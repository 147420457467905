import './src/theme/destyle.css'
import './src/theme/global.css'

import { ThemeProvider } from './src/theme'
import { Transition } from './src/utils/transition'

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider {...props}>
      <Transition {...props}>{element}</Transition>
    </ThemeProvider>
  )
}
